
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import { BooleanColumnConfig } from "@/components/controls/catalogueGrid/columns/booleanColumnConfig";
import { DecimalColumnConfig } from "@/components/controls/catalogueGrid/columns/decimalColumnConfig";
import { TableImportConfig } from "@/components/controls/catalogueGrid/tableImportConfig";
import { TableExportConfig } from "@/components/controls/catalogueGrid/tableExportConfig";
import { EnumColumnConfig } from "../../controls/catalogueGrid/columns/enumColumnConfig";
import store from "@/store";
import planCargoRollCreateEditDialog from "./PlanCargoRollCreateEditDialog.vue";
import planCargoPalletCreateEditDialog from "./PlanCargoPalletCreateEditDialog.vue";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
    //модель родительского элемента
    parentModel: { required: true },
    //идет расчет плана
    isCalculation: { required: false },
    //открыты ли настройки
    isSettingsOpen: { type: Object, required: true },
  },
  computed: {
    planCargoPalletCreateEditDialog(): any {
      return planCargoPalletCreateEditDialog;
    },
    planCargoRollCreateEditDialog(): any {
      return planCargoRollCreateEditDialog;
    },
    popupCreateEditComponent(): any {
      return this.popupCreateEditComponent;
    },
  },
  methods: {
    setColums() {
      if (ability.can("view", "Modules.CargoPriority")) {
        this.columns.push(
          new BooleanColumnConfig({
            dataField: "highPriority",
            caption: "Обязательно для погрузки",
          })
        );
      }

      if (ability.can("view", "Modules.CargoQueue")) {
        this.columns.push(
          new IntegerColumnConfig({
            dataField: "queuePosition",
            caption: "Порядок погрузки",
            validationRules: [requiredRule],
          })
        );
      }

      if (this.separationWeight) {
        this.columns.push(
          new DecimalColumnConfig({
            dataField: "weight2",
            caption: "Вес нетто (кг)",
            decimalPoints: 0,
            hideFraction: true,
            validationRules: [requiredRule],
          })
        );

        this.columns.push(
          new DecimalColumnConfig({
            dataField: "totalWeight2",
            caption: "Суммарный вес нетто (кг)",
            decimalPoints: 0,
            hideFraction: true,
            validationRules: [requiredRule],
          })
        );
      } else {
        this.columns.filter((x) => x.dataField == "weight")[0].caption =
          "Вес (кг)";
        this.columns.filter((x) => x.dataField == "totalWeight")[0].caption =
          "Суммарный вес (кг)";
      }

      if (ability.can("view", "Modules.Order")) {
        this.columns.push(
          new DecimalColumnConfig({
            dataField: "marginMin",
            caption: "Отклонение (-)",
            decimalPoints: 1,
            validationRules: [requiredRule],
          })
        );
        this.columns.push(
          new DecimalColumnConfig({
            dataField: "margin",
            caption: "Отклонение (+)",
            decimalPoints: 1,
            validationRules: [requiredRule],
          })
        );
        this.columns.push(
          new TextColumnConfig({
            dataField: "orderDescription",
            caption: "Заказ",
          })
        );
      }
    },
    addPallet() {
      this.popupCustomeCreateEditComponent =
        this.planCargoPalletCreateEditDialog;
      (this.$refs.grid as any).createNewRow();
    },
    addRoll() {
      this.popupCustomeCreateEditComponent = this.planCargoRollCreateEditDialog;
      (this.$refs.grid as any).createNewRow();
    },
    editRow(e: any): void {
      if (e.row.data.type == 0)
        this.popupCustomeCreateEditComponent =
          this.planCargoRollCreateEditDialog;
      if (e.row.data.type == 1)
        this.popupCustomeCreateEditComponent =
          this.planCargoPalletCreateEditDialog;

      (this.$refs.grid as any).editRow(e);
    },
    onRowDblClick(e: any) {
      const grid = this.$refs.grid as any;
      //предотвращаем вызов события в master таблице, если событие из detail таблицы
      e.event.stopPropagation();

      grid.$emit("row-dblclick", e);
      if (grid.getSelectedData && !e.data.collapsedItems)
        grid.getSelectedData((grid.dxGrid as any).getSelectedRowsData());

      this.editRow({ row: e });
    },
    async onToolbarPreparing(e: any) {
      if (this.repaintPreventKey) return;
      this.repaintPreventKey = true;

      //удаляем стандартную кнопку добавления
      e.toolbarOptions.items = e.toolbarOptions.items.filter(
        (x: any) => x.name != "addButton"
      );

      if (ability.can("view", "Modules.CargoPallet") && ability.can("view", "Documents.PlanCargoPallet")) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            text: "Добавить паллету",
            stylingMode: "outlined",
            onClick: this.addPallet,
          },
        });
      }

      if (ability.can("view", "Modules.CargoRoll") && ability.can("view", "Documents.PlanCargoRoll")) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            text: "Добавить рулон",
            stylingMode: "outlined",
            onClick: this.addRoll,
          },
        });
      }
    },
  },
  data() {
    return {
      separationWeight: false,
      popupCustomeCreateEditComponent: null,
      // подробности работы custom summary см. на сервере в методе SetCustomSummary
      summaryConfig: {
        totalItems: [
          {
            name: "TotalSummary",
            showInColumn: "name",
            displayFormat: "Итог:",
            summaryType: "custom",
          },
          {
            name: "TotalWeightSummary",
            showInColumn: "totalWeight",
            column: "totalWeight",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
          {
            name: "TotalWeightSummary2",
            showInColumn: "totalWeight2",
            column: "totalWeight2",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
          {
            name: "CountSummary",
            showInColumn: "count",
            column: "count",
            valueFormat: "#,##0.#",
            displayFormat: "{0} шт",
            summaryType: "sum",
          },
        ],
        groupItems: [],
      },

      editingConfig: new TableEditingConfig({
        allowDeleteAll: !this.isCalculation,
        allowUpdating: !this.isCalculation,
        allowAdding: !this.isCalculation,
        allowDeleting: !this.isCalculation,
        confirmDelete: true,
        allowDataFill: !this.isCalculation,
        allowExport: false,
        allowImport: false,
        allowCopy: true,
        allowDeleteMultipleRows: true,
        allowChangeScrollSettings: true,
        allowColumnReordering: true,
        allowGrouping: false,
        allowResetSelectedRows: true,
        chooseColumns: true,
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        heightScroll: "calc(100vh - 350px)",
        horizontalScroll: true,
      }),
      columns: [
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
        }),

        new EnumColumnConfig({
          dataField: "typeDescription",
          caption: "Тип",
          enumType: "cargoType",
          modelField: "type",
          modelDescField: "typeDescription",
          descriptionColumnName: "typeDescription",
        }),

        new TextColumnConfig({
          dataField: "dimensions",
          caption: "Размеры",
        }),

        new DecimalColumnConfig({
          dataField: "weight",
          caption: "Вес брутто (кг)",
          decimalPoints: 0,
          hideFraction: true,
          validationRules: [requiredRule],
        }),

        new IntegerColumnConfig({
          dataField: "count",
          caption: "Кол-во (шт)",
          validationRules: [requiredRule],
          min: 0,
        }),

        new DecimalColumnConfig({
          dataField: "totalWeight",
          caption: "Суммарный вес брутто (кг)",
          decimalPoints: 0,
          hideFraction: true,
          validationRules: [requiredRule],
        }),
      ],
      importConfig: new TableImportConfig({
        progressMethodName: "importPlanCargoProgress",
        progressEventName: "importPlanCargoProgressEvent",
        messageEventName: "importPlanCargoMessageEvent",
      }),
      exportConfig: new TableExportConfig({
        progressMethodName: "exportPlanCargoProgress",
        progressEventName: "exportPlanCargoProgressEvent",
        messageEventName: "exportPlanCargoMessageEvent",
        exportFileName: "Грузы-Opti-Loading",
      }),
      selectionConfig: new TableSelectionConfig({
        mode: "multiple",
        selectAllMode: "allPages",
        showCheckBoxesMode: "none",
      }),
      pageSizes: [10, 20, 50, 100],
      repaintPreventKey: false,
      toolbarItems: this.isCalculation ? [] : [],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }

    const settings = (store as any).state.settings.settings;

    if (settings) {
      this.separationWeight = settings.separationWeight;
    }

    this.setColums();
  },
  mounted() {
    const grid = this.$refs.grid as any;
    grid.editButtons.filter((x) => x.icon == "edit")[0].onClick = this.editRow;
    grid.onRowDblClick = this.onRowDblClick;
  },
});
